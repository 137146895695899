<template>
  <fr-button
    v-if="useCheckPermissions(['chatbot | view'])"
    :icon="state.show.value ? 'close' : 'fal fa-robot'"
    @click="state.show.value = !state.show.value"
    class="border border-fr-gray-250 !bg-fr-gray-200 !text-fr-gray-900 !shadow-none dark:border-none dark:!bg-fr-gray-850 dark:!text-fr-gray-250"
  >
    <q-tooltip>
      {{ state.show.value ? 'Close chatbot' : 'Talk to AI chatbot' }}
    </q-tooltip>

    <!-- <span class="hidden md:block"> Chatbot </span> -->
  </fr-button>
  <TransitionIn>
    <vue-final-modal
      ref="chatbot-modal"
      key="chatbot-modal"
      v-model="state.show.value"
      :data-index="2"
      :lock-scroll="false"
      :resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
      resize
      drag
      hide-overlay
      keep-changed-style
      prevent-click
      classes="flex justify-center items-center"
      content-class="flex flex-col w-full h-full p-1 md:w-[400px] md:max-h-[80vh] md:min-h-[400px] h-full absolute lg:bottom-[40px] lg:right-[40px] md:min-w-[500px] md:max-w-[80vw] overflow-hidden flex-1 flex-nowrap !rounded-md !shadow-lg z-50 bg-fr-gray-0 dark:bg-fr-gray-800"
    >
      <div class="sticky top-0 z-20 flex flex-row justify-between p-2">
        <h3
          class="flex items-center gap-2 text-base text-fr-gray-900 dark:text-fr-gray-100"
        >
          <q-icon
            name="fa fa-robot"
            class="text-fr-gray-900 dark:text-fr-gray-100"
          />
          <span class="ml-2">Arnie Bot</span>
          <q-chip label="Beta" size="sm" color="secondary" class="text-white" />
        </h3>
        <fr-button icon="close" @click="state.show.value = false" />
      </div>
      <chatbot-component />
    </vue-final-modal>
  </TransitionIn>
</template>

<script setup lang="ts">
// @ts-expect-error This works
import { VueFinalModal } from 'vue-final-modal'

import ChatbotComponent from '../chatbot-component.vue'

const state = toRefs(
  reactive<{
    show: boolean
  }>({
    show: false
  })
)
</script>
