<template>
  <fr-button
    icon="fal fa-megaphone"
    @click="showDialog = true"
    class="border border-fr-gray-250 !bg-fr-gray-200 !text-fr-gray-900 !shadow-none dark:border-none dark:!bg-fr-gray-850 dark:!text-fr-gray-250"
  >
    <q-tooltip>Service Messages</q-tooltip>
  </fr-button>

  <q-dialog
    v-model="showDialog"
    :maximized="
      !!(useDeviceCheck() === 'mobile' || useDeviceCheck() === 'tablet')
    "
    position="right"
    persistent
    class="shadow-none"
  >
    <service-message-modal />
  </q-dialog>
</template>

<script setup lang="ts">
import { useAdminStore } from '~/features/admin/store'
import serviceMessageModal from './service-message-modal.vue'

const adminStore = useAdminStore()

const showDialog = computed({
  get: () => adminStore.CreateServiceMessageDialogShow,
  set: (value) => (adminStore.CreateServiceMessageDialogShow = value)
})
</script>
