<template>
  <div class="z-50 flex flex-row items-center justify-center gap-2">
    <!-- User count -->

    <client-only>
      <service-message-button
        v-if="user && user.id && useCheckPermissions(['admin | view'])"
      />

      <default-printers-button v-if="user && user.id" :variant="null" />
      
      <fr-button 
        v-if="users" 
        icon="fal fa-users"
        class="border border-fr-gray-250 !bg-fr-gray-200 !text-fr-gray-900 !shadow-none dark:border-none dark:!bg-fr-gray-850 dark:!text-fr-gray-250"
      >
        <q-tooltip> {{ users }} users online </q-tooltip>
        <q-badge v-if="users" align="middle" class="max-h-8 -mt-1 -mr-0.5" floating>
          {{ users }}
        </q-badge>
      </fr-button>

      <app-header-chatbot-modal />

      <dark-mode-component />

      <country-selector v-if="user && user.id" />

      <div
        v-if="user && user.id"
        class="rounded-by-style px-1 hover:cursor-pointer hover:bg-fr-gray-250 dark:hover:bg-fr-gray-850"
      >
        <div
          class="!flex w-auto items-center justify-start gap-2 !bg-transparent p-1"
        >
          <div class="h-10 w-10 items-center !rounded-full !p-0">
            <fr-image
              class="h-full w-full rounded-full"
              :src="
                user?.profilePicture ||
                wrikeUser?.avatar_url ||
                'https://www.gravatar.com/avatar/de3c32db0ca79f1e0f4bfcf42a1d6451.jpg?s=80&d=mm&r=g'
              "
            />
          </div>

          <div class="flex flex-col items-start">
            <p>{{ user?.name }}</p>
            <p class="-mt-1 text-[10px]">{{ user.email }}</p>
          </div>
        </div>

        <!-- profile dropdown -->
        <q-menu
          class="m-0 min-w-[250px] p-0 shadow-none"
          transition-show="jump-down"
          transition-duration="400"
          :offset="[0, 5]"
        >
          <fr-card>
            <!-- <fr-card-header> -->
            <div class="mb-1 flex items-center justify-start gap-3 p-3">
              <div class="h-10 w-10">
                <fr-image
                  class="h-full w-full rounded-full"
                  :src="
                    user?.profilePicture ||
                    wrikeUser?.avatar_url ||
                    'https://www.gravatar.com/avatar/de3c32db0ca79f1e0f4bfcf42a1d6451.jpg?s=80&d=mm&r=g'
                  "
                />
              </div>

              <div class="item-start flex flex-col">
                <h4 class="text-lg">{{ user?.name }}</h4>
                <span class="text-xs font-light">{{ user?.jobTitle }}</span>
              </div>
            </div>
            <q-separator />

            <!-- <template #right>
                <fr-button
                  variant="ghost"
                  icon="account_circle"
                  @click="() => $router.push('/auth/account')"
                >
                  <q-tooltip> {{ $t('account.my_account') }} </q-tooltip>
                </fr-button>
              </template> -->
            <!-- </fr-card-header> -->

            <!-- new menu items layout -->
            <div>
              <!-- profile -->
              <p
                @click="() => $router.push('/auth/account')"
                class="item-center flex justify-start gap-2 px-3 py-2 hover:cursor-pointer dark:hover:bg-fr-gray-850 hover:bg-fr-gray-300"
              >
                <q-icon name="fa fa-user" class="mt-1" />
                View Profile
              </p>

              <!-- region -->
              <p
                class="item-center flex justify-start gap-2.5 px-3 py-2 hover:cursor-pointer dark:hover:bg-fr-gray-850 hover:bg-fr-gray-300"
              >
                <q-icon name="fas fa-globe-africa" class="mt-1" />
                Regions

                <q-tooltip> Region filter </q-tooltip>

                <q-menu
                  class="m-0 p-0 shadow-none"
                  transition-show="jump-down"
                  transition-duration="400"
                  :offset="[0, 20]"
                >
                  <fr-card>
                    <fr-card-body>
                      <fr-checkbox
                        v-for="(country, index) in countries"
                        :key="index"
                        v-model="country.selected"
                        :name="country.name"
                        :description="country.label"
                        :icon="`img:/warehouse/${country.name}.svg`"
                        size="md"
                      />
                    </fr-card-body>
                  </fr-card>
                </q-menu>
              </p>

              <!-- language -->
              <p
                class="item-center flex justify-between gap-2.5 px-3 py-2 hover:cursor-pointer dark:hover:bg-fr-gray-850 hover:bg-fr-gray-300"
              >
                <div class="item-center flex justify-start gap-2.5">
                  <q-icon name="fa fa-language" class="mt-1" />
                  Language
                </div>

                <p>{{ state.selected.value.label }}</p>
                <q-tooltip> Language Picker </q-tooltip>

                <q-menu class="m-0 min-w-[150px] p-0 shadow-none" :offset="[0, 10]">
                  <div class="fr-card">
                    <q-list>
                      <template
                        v-for="language in state.languages.value"
                        :key="language.label"
                      >
                        <q-item
                          clickable
                          :class="
                            state.selected.value.value === language.value
                              ? 'text-cyan-500'
                              : ''
                          "
                          @click="
                            () => {
                              state.selected.value = language
                              $i18n.locale = language.value
                            }
                          "
                        >
                          <q-item-section
                            class="flex flex-row items-center justify-between"
                          >
                            {{ language.label }}

                            <span
                              v-if="language.isAI"
                              class="text-xs font-semibold text-fr-secondary-500"
                            >
                              AI
                            </span>

                            <q-tooltip v-if="language.isAI" :offset="[0, 10]">
                              this language is automatically translated
                            </q-tooltip>
                          </q-item-section>
                        </q-item>
                        <q-separator />
                      </template>
                    </q-list>
                  </div>
                </q-menu>
              </p>
            </div>

            <q-separator />

            <!-- <fr-card-body class="!flex-row justify-between !py-3">
              <regions-dropdown />
              <lang-picker />
            </fr-card-body>

            <q-separator /> -->

            <div class="flex flex-row flex-nowrap justify-between p-2">
              <fr-button
                v-close-popup
                variant="ghost"
                icon="refresh"
                @click="() => useForceReset(true)"
              >
                <q-tooltip>{{ $t('account.force_reset') }}</q-tooltip>
              </fr-button>

              <fr-button
                v-close-popup
                variant="ghost"
                icon="logout"
                @click="
                  () => {
                    showLogoutDialog = true
                  
                    // $q
                    //   .dialog({
                    //     title: $t('logout.confirm'),
                    //     message: $t('logout.logout_confirmation'),
                    //     ok: $t('logout.ok'),
                    //     cancel: $t('logout.cancel')
                    //   })
                    //   .onOk(() =>
                    //     refetchLogout().then(() => useForceReset(false, false))
                    //   )
                  }
                "
              >
                Logout
              </fr-button>
            </div>
          </fr-card>
        </q-menu>
      </div>
    </client-only>
  </div>


  <!-- logout dialog: so that users know logout call is happening  -->
  <q-dialog
    v-if="!loggedOut"
    v-model="showLogoutDialog"
  >
    <fr-card style="width: 700px; max-width: 80vw;">
      <fr-card-header title="Logout confirmation">
        <template #right>
          <fr-button
            variant="ghost"
            icon="close"
            @click="showLogoutDialog = false"
          />
        </template>
      </fr-card-header>

      <q-card-section
        v-if="isLoggingOut"
        class="text-lg gap-5 flex justify-center items-center my-5"
      >
        <q-spinner-dots size="50px" color="primary" />
        Logging you out ... 
      </q-card-section>

      <q-card-section v-else class="text-lg flex justify-center my-5">
        You are about to log out. Do you want to continue?
      </q-card-section>

      <q-card-actions v-if="!isLoggingOut" align="right" >
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn 
          flat 
          label="OK" 
          @click="
            () => {
              refetchLogout().then(() => useForceReset(false, false))
            }
          " 
        />
      </q-card-actions>
    </fr-card>
  </q-dialog>

</template>

<script setup lang="ts">
import RegionsDropdown from '~/components/widgets/regions-dropdown-widget.vue'
import { useServiceDeskStore } from '~/features/service-desk/store'
import darkModeComponent from './theme-components/theme-switch-button.vue'
import ChatbotModal from '~/features/chatbot/components/modals/chatbot-modal.vue'
import AppHeaderChatbotModal from '~/features/chatbot/components/modals/app-header-chatbot-modal.vue'
import { io } from 'socket.io-client'
import factories from '~/factories'
import CountrySelector from '~/features/country/components/country-selector-widget.vue'
import defaultPrintersButton from '~/features/printer/components/default-printers-button.vue'
import serviceMessageButton from '~/features/admin/components/service-messages/service-message-button.vue'
import useAuthStore from '~/features/auth/store'

const showLogoutDialog = ref<boolean>(false)

const ServiceDeskStore = useServiceDeskStore()
const $q = useQuasar()

const users = ref()
const user = useUser()

const socket = io(process.env.FR_PERMISSIONS_URL as string, {})

socket.on('users', (arg) => {
  users.value = arg
})

const wrikeUser = computed({
  get: () => ServiceDeskStore.wrikeUser,
  set: (value) => (ServiceDeskStore.wrikeUser = value)
})

const { refetch: refetchLogout, isFetching: isLoggingOut, isSuccess: loggedOut } = factories.auth.queries.useLogout({
  enabled: false
})

// regions filter
const emit = defineEmits(['update:modelValue', 'update-selected'])

const store = useAuthStore()

const selectedCountries = computed({
  get: () => store.countries.filter((country) => country.selected),
  set: (value) => {
    emit('update:modelValue', value)
    emit('update-selected', value)
  }
})

const countries = computed({
  get: () => store.countries,
  set: (value) => (store.countries = value)
})


// language picker
const state = toRefs(
  reactive<{
    languages: {
      label: string
      shortLabel: string
      value: 'en-za' | 'de-de' | 'af-za' | 'fr-fr'
      isAI?: boolean
    }[]
    selected: {
      label: string
      shortLabel: string
      value: 'en-za' | 'de-de' | 'af-za' | 'fr-fr'
      isAI?: boolean
    }
  }>({
    languages: [
      {
        label: 'English',
        shortLabel: 'Eng',
        value: 'en-za',
        isAI: false
      },
      {
        label: 'German',
        shortLabel: 'Ger',
        value: 'de-de',
        isAI: true
      },
      {
        label: 'Afrikaans',
        shortLabel: 'Afr',
        value: 'af-za',
        isAI: true
      },
      {
        label: 'French',
        shortLabel: 'Fre',
        value: 'fr-fr',
        isAI: true
      }
    ],
    selected: {
      label: 'English',
      shortLabel: 'Eng',
      value: 'en-za',
      isAI: false
    }
  })
)
</script>
